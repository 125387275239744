import {
  PAGE_LIMIT,
  directus,
  fileDelete,
  fileUpload,
  getPagingQuery,
  getRoleId,
  nextAgentNum,
} from "./directus";

export const USERS_FIELDS = [
  "id",
  "avatar",
  "email",
  "mob_phone",
  "ofc_phone",
  "first_name",
  "last_name",
  "country",
  "org_no",
];

// 사용자 정보가 없으면 null 반환
export const getUserInfo = async (id) => {
  //console.log("getUserInfo: id=", id);
  let result = (await directus.users.readOne(id)) || null;
  //console.log("getUserInfo: result=", result);
  return result;
};

/**
 * 대리인(agent) 정보 읽기
 * @param {number} agentNum
 * @returns {object}
 */
export const readAgentInfo = async (agentNum) => {
  //console.log("user.js : readAgentInfo, agentNum=", agentNum);
  if (!agentNum) return null;

  let result = null;
  let fields = [
    "id",
    "agent_num",
    "first_name",
    "avatar",
    "email",
    "mob_phone",
    "ofc_phone",
  ];
  let filter = { _and: [] };
  filter._and.push({ status: { _eq: "active" } });
  filter._and.push({ agent_num: { _eq: agentNum } });
  //console.log("user.js : readAgentInfo, filter=", filter);
  let { data } = await directus.users.readByQuery({
    fields,
    filter,
  });
  if (data && data.length > 0) result = data[0];

  //console.log("user.js : readAgentInfo, result=", result);
  return result;
};

/**
 * 사용자목록
 * @param {role, keyword} param0
 * @returns {}
 */
export const getUserList = async ({
  roleName = "_NONE_",
  keyword,
  isPaging = true,
  limit = PAGE_LIMIT,
  page = 1,
}) => {
  let filter = {};
  filter._and = [];
  //filter._and.push({ status: { _eq: "active" } });
  roleName && filter._and.push({ role: { name: { _eq: roleName } } });

  const query = {
    fields: USERS_FIELDS,
    search: keyword,
    filter,
    meta: "*",
  };

  const result = await directus.users.readByQuery(
    isPaging ? getPagingQuery(query, page, limit) : query
  );

  result.totalCount = result.meta.filter_count;
  result.pageParam = page;

  return result;
};

/**
 * 사용자 등록 / 수정
 * @param {Object} userData
 * @param {Object} userData.formData
 * @param {("customer"|"supplier"|"agency"|"agent"|"master")} userData.roleName
 * @returns {Object}
 */
export const saveUser = async ({ formData = {}, roleName }) => {
  console.log("user.js : saveUser, formData=", formData);
  //console.log("user.js : saveUser, roleName=", roleName);
  let registData = { ...formData };

  let fileCnt = formData.profile?.length || 0;
  switch (fileCnt) {
    case 0: // 업로드 할 file이 없으면 avatar 속성 제거
      delete registData.avatar;
      break;
    case 1: // 업로드 한 파일의 id 값을 avatar에 넣기
      await fileDelete(formData.avatar); // 기존 파일 삭제
      registData.avatar = await fileUpload("user", formData.profile);
      break;
    default: // 업로드 한 파일의 id 값들 중에 유효한 첫번째 id를 avatar에 넣기
      await fileDelete(formData.avatar); // 기존 파일 삭제
      registData.avatar =
        (await fileUpload("user", formData.profile))?.find((id) => id) || null;
  }
  delete registData.profile;
  console.log("user.js : saveUser, formData=", formData);

  let result;
  if (registData.id) {
    const serverData = await directus.users.readOne(registData.id, {
      fields: ["id", "role.name"],
    });
    const roleName = serverData.role.name;

    // 사용자 정보 업데이트 처리
    // 롤은 업데이트에서 제외
    let userId = registData.id;
    delete registData.id;
    delete registData.role;
    delete registData.agent_num; //agency>adgnt를 위해
    // supplier, agency 수정시 처리를 위해 roleName이 supplier, agency 아닐때만 org_no delete
    if (roleName !== "supplier" && roleName !== "agency")
      delete registData.org_no; //agency>adgnt를 위해
    // TODO : 추후 비밀번호 처리 추가 필요
    // 비밀번호 업데이트에서 제외
    delete registData.password;
    delete registData.confirm_password;
    // !!주의 : registData.agent_sales_info 정보는 직접 수정하지 않음
    delete registData.agent_sales_info;
    //console.log("user.js : saveUser, registData=", registData);
    result = await directus.users.updateOne(userId, registData);
  } else {
    // 사용자 정보 등록 처리
    delete registData.id;
    registData.role = await getRoleId(roleName);
    roleName === "agent" &&
      !registData.agent_num &&
      (registData.agent_num = await nextAgentNum());
    registData.status = "active";

    //현장대리인 등록시 주문 카운트 및 합계금액 정보 추가
    if (roleName === "agent") {
      registData.agent_sales_info = {
        agent_cust_cnt: 0,
        agent_contr_cnt: 0,
        agent_contr_amt: 0,
      };

      // 현장대리인 등록시 status suspended 처리
      registData.status = "suspended";
    }
    //console.log("user.js : saveUser, registData=", registData);
    result = await directus.users.createOne(registData);
  }

  /* console.log(
    `user.js : saveUser, result = ${JSON.stringify(result, null, 5)}`
  ); */
  return result;
};

/**
 * 사용자 상태변경 inactive / active
 * @param {string} id
 * @param {string} status
 * @returns {}
 */
export const changeUserStatus = async ({ id, status }) => {
  if (!id || !status) {
    return Promise.reject(
      new Error("Please check the required fields and try again.")
    );
  }

  // 상태변경
  let result = await directus.users.updateOne(id, { status });

  // 결과 메시지
  let msg = "It has been deactivated.";
  if (status === "active") msg = "It has been inactivated.";
  result.rsMsg = msg;

  return result;
};
