import {
  addDay,
  directus,
  formatDate,
  getPagingQuery,
  PAGE_LIMIT,
  setStorage,
  storageKey,
  subtractMonth,
  todayFormattedDate,
} from "./directus";
import { ADDRESS_BOOK_FIELDS } from "./addressbook";
import { INTERIOR_PLANS_FIELDS } from "./interiorplan";
import { PLAN_ITEMS_FIELDS } from "./planitem";
import { USERS_FIELDS } from "./user";

const TAX_INFO_FIELDS = [
  "tax_info_no",
  "tax_code",
  "comp_nm",
  "ofc_phone",
  "email",
  "address",
];
export const CONTRACTS_FIELDS = [
  "contr_no",
  "cust_no",
  ...USERS_FIELDS.map((field) => "cust_no.".concat(field)),
  ...INTERIOR_PLANS_FIELDS.map((field) => "plan_no.".concat(field)),
  "agent_no.id",
  "agent_no.agent_num",
  "item_price_sum",
  "dc_plcy_no.plcy_no",
  "dc_plcy_no.dc_rate_percent",
  "dc_amt",
  "final_price_tot",
  "dpst_amt",
  "baln_amt",
  ...TAX_INFO_FIELDS.map((field) => "tax_info_no.".concat(field)),
  "cnfrm_date",
  "order_id",
  "order_status",
  "deliv_due_date",
  "deliv_addr_no",
  ...ADDRESS_BOOK_FIELDS.map((field) => "deliv_addr_no.".concat(field)),
  "recip_phone_num",
  "recip_email",
  "recip_nm",
  "dpst_due_date",
  "dpst_pay_date",
  "baln_pay_date",
  "compl_date",
  "new_item_checkers.directus_users_id",
  "deliv_due_date_int",
  "compl_date_int",
];

/**
 * 계약건수
 * @param {cust_no}
 * @returns {}
 */
export const getContractAndHistoryCounts = async ({ cust_no }) => {
  let counts = {
    contract: 0,
    purchasehistory: 0,
  };

  let filter = {};
  filter._and = [{ status: { _eq: "published" } }];
  cust_no && filter._and.push({ cust_no: { _eq: cust_no } });

  const result = await directus.items("contracts").readByQuery({
    fields: ["contr_no", "cust_no", "order_status"],
    filter,
    meta: "*",
  });

  if (result?.data) {
    counts.plan = result.data.filter(
      (cont) =>
        cont.order_status === "Planning" || cont.order_status === "Confirmed"
    ).length;
    counts.purchasehistory = result.data.filter(
      (cont) =>
        cont.order_status === "Completed" || cont.order_status === "Canceled"
    ).length;
    counts.contract = result.data.length - counts.plan - counts.purchasehistory;
  }

  return counts;
};

/**
 * 계약목록
 * @param {cust_no, house_type, order_status, keyword,} param0
 * @returns []
 */
export const getContractList = async (params) => {
  //console.log("contract.js : getContractList, params=", params);

  const {
    cust_no,
    house_type,
    order_status,
    isCompleted = false,
    keyword,
    isPaging = true,
    limit = PAGE_LIMIT,
    page = 1,
  } = params;

  let filter = {};
  filter._and = [{ status: { _eq: "published" } }];
  cust_no && filter._and.push({ cust_no: { _eq: cust_no } });
  house_type &&
    filter._and.push({
      plan_no: { floor_no: { house_type: { _eq: house_type } } },
    });
  order_status &&
    typeof order_status === "string" &&
    filter._and.push({ order_status: { _eq: order_status } });
  order_status &&
    typeof order_status === "object" &&
    filter._and.push({ order_status: { _in: order_status } });
  keyword && filter._and.push({ plan_no: { plan_nm: { _contains: keyword } } });

  if (!!isCompleted) {
    // ... AND ( "Completed" OR "Canceled")
    let subfilter = { _or: [] };
    subfilter._or.push({ order_status: { _eq: "Completed" } });
    subfilter._or.push({ order_status: { _eq: "Canceled" } });
    filter._and.push(subfilter);
  } else {
    // ... AND (not "Completed") AND (not Canceled)
    filter._and.push({ order_status: { _neq: "Completed" } });
    filter._and.push({ order_status: { _neq: "Canceled" } });
  }

  //console.log("getContractList, filter=", filter);
  const query = {
    fields: CONTRACTS_FIELDS,
    filter,
    sort: ["-date_updated"],
    meta: "*",
  };

  const result = await directus
    .items("contracts")
    .readByQuery(isPaging ? getPagingQuery(query, page, limit) : query);

  result.totalCount = result.meta.filter_count;
  result.pageParam = page;

  return result.data;
};

/**
 * 계약상세정보
 * @param {contr_no}
 * @returns {}
 */
export const getContractDetail = async ({ contr_no }) => {
  let filter = {};
  filter._and = [];
  contr_no && filter._and.push({ contr_no: { _eq: contr_no } });

  const result = await directus
    .items("contracts")
    .readOne(contr_no, { fields: CONTRACTS_FIELDS });

  return result;
};

/**
 *
 * @param {object} contract
 * @returns {(object | null)} result
 */
export const writeContract = async (contract) => {
  //console.log("contract.js : writeConract, contract=", contract);
  if (!(contract && Object.keys(contract).length > 0)) return null;

  let result = null;
  if (!contract.contr_no) {
    // insert : plan 생성할 때 contract도 동시에 생성
    appendIntegerDateValue(contract);
    result = await directus.items("contracts").createOne(contract);
  } else {
    // update : 이후에는 모두 업데이트
    result = await updateOrderStatus(contract);
  }

  //console.log("contract.js : writeConract, result=", result);
  return result;
};
const appendIntegerDateValue = (contract) => {
  if (!contract) return;
  if (contract.deliv_due_date)
    contract.deliv_due_date_int = Number(contract.deliv_due_date);
  if (contract.compl_date)
    contract.compl_date_int = Number(contract.compl_date);
  return contract;
};

export const deleteContract = async ({ contr_no }) => {
  // status "archived" 로 update 하여 삭제 처리
  const result = await directus
    .items("contracts")
    .updateOne(contr_no, { status: "archived" });

  return result;
};

export const updateOrderStatus = async (params) => {
  //console.log("contract.js : updateOrderStatus, params = ", params);

  const contr_no = params.contr_no;
  delete params.contr_no;
  params.new_item_checkers = [];
  /* console.log(
    "contract.js : updateOrderStatus, contr_no, params = ",
    contr_no,
    params
  ); */
  appendIntegerDateValue(params);
  const result = await directus.items("contracts").updateOne(contr_no, params);

  //console.log("contract.js : updateOrderStatus, result = ", result);
  return result;
};

/**
 * Deposiot Confirm 처리
 * supplier_orders 목록 + contract.order_status 업데이트
 */
export const confirmDeposit = async ({ contr_no, plan_no, dpst_pay_date }) => {
  //console.log("contract.js : confirmDeposit, contr_no = ", contr_no);
  //console.log("contract.js : confirmDeposit, plan_no = ", plan_no);

  const { data: planItems } = await directus.items("plan_items").readByQuery({
    fields: PLAN_ITEMS_FIELDS,
    filter: { plan_no: { _eq: plan_no } },
  });
  //console.log("contract.js : confirmDeposit, planItems = ", planItems);
  const supplOrders = planItems?.reduce((supplNoList, planItem) => {
    let index = supplNoList.findIndex(
      (supplNo) => supplNo.supp_org_no === planItem.prod_no.suppl_no
    );
    if (index < 0) supplNoList.push({ supp_org_no: planItem.prod_no.suppl_no });
    return supplNoList;
  }, []);
  //console.log("contract.js : confirmDeposit, supplOrders = ", supplOrders);
  const result = await updateOrderStatus({
    contr_no: contr_no,
    order_status: "Order Placed",
    suppl_orders: supplOrders,
    dpst_pay_date: dpst_pay_date,
  });
  //console.log("contract.js : confirmDeposit, result = ", result);
  return result;
};

/**
 * 할인정책 처리 관련 시작
 */

export const dcAlertMessage = (
  order_status,
  dcInfo,
  withDateRange = false,
  userCountry = "Blank"
) => {
  //console.log("contract.js : dcAlertMessage, order_status=", order_status);
  //console.log("contract.js : dcAlertMessage, generateDcInfoWithPolicyList=", generateDcInfoWithPolicyList);
  //console.log("contract.js : dcAlertMessage, withDateRange=", withDateRange);

  let result = "Feel free to contact us anytime!";
  if (order_status && !!dcInfo?.dc_rate_percent) {
    if (order_status === "Planning") {
      if (withDateRange)
        result = `${dcInfo.dc_rate_percent}% OFF (${formatDate(
          dcInfo.dc_start_date,
          userCountry
        )}~${formatDate(dcInfo.dc_end_date, userCountry)})`;
      else result = `Confirm your order to get ${dcInfo.dc_rate_percent}% OFF`;
    } else result = `${dcInfo.dc_rate_percent}% OFF (applied discount)`;
  }

  //console.log("contract.js : dcAlertMessage, result=", result);
  return result;
};

export const todayDcInfoWithPolicyList = (
  deliv_due_date,
  dcPolicyList,
  userCountry
) =>
  generateDcInfoWithPolicyList(
    todayFormattedDate(userCountry),
    deliv_due_date,
    dcPolicyList,
    userCountry
  );
export const generateDcInfoWithPolicyList = (
  ref_date,
  deliv_due_date,
  dcPolicyList
) => {
  //console.log("contract.js : generateDcInfoWithPolicyList, ref_date=", ref_date);
  //console.log("contract.js : generateDcInfoWithPolicyList, deliv_due_date=", deliv_due_date);
  //console.log("contract.js : generateDcInfoWithPolicyList, dcPolicyList=", dcPolicyList);
  let result = null;
  if (ref_date && deliv_due_date && dcPolicyList?.length > 0) {
    //dcPolicyList : 할인율 높은 정책부터 정렬되어 할인종료일만 계속 체크하면 됨.
    for (const dcPolicy of dcPolicyList) {
      result = generateDcInfoFromDcPolicy(deliv_due_date, dcPolicy);
      if (result && ref_date <= result.dc_end_date) break;
    }
  }
  //console.log("contract.js : generateDcInfoWithPolicyList, result=", result);
  return result;
};
export const generateDcInfoFromDcPolicy = (deliv_due_date, dcPolicy) => {
  //console.log("contract.js : getDcInfo, deliv_due_date=", deliv_due_date);
  //console.log("contract.js : getDcInfo, dcPolicy=", dcPolicy);
  let result = null;
  if (deliv_due_date?.length === 8 && dcPolicy?.plcy_no) {
    let dc_start_date = dcPolicy.dc_start_fac
      ? addDay(subtractMonth(deliv_due_date, dcPolicy.dc_start_fac), 1)
      : null;
    //console.log("contract.js : getDcInfo, dc_start_date=", dc_start_date);
    let dc_end_date = dcPolicy.dc_start_fac
      ? subtractMonth(deliv_due_date, dcPolicy.dc_end_fac)
      : null;
    //console.log("contract.js : getDcInfo, dc_end_date=", dc_end_date);
    result = {
      plcy_no: dcPolicy.plcy_no,
      dc_rate_percent: dcPolicy.dc_rate_percent,
      dc_start_date: dc_start_date,
      dc_end_date: dc_end_date,
    };
  }
  //console.log("contract.js : getDcInfo, result=", result);
  return result;
};

export const dcInfoWithPlcyNo = async (deliv_due_date, plcy_no) => {
  /* console.log(
    "contract.js : dcInfoWithPlcyNo, deliv_due_date=",
    deliv_due_date
  ); */
  //console.log("contract.js : dcInfoWithPlcyNo, plcy_no=", plcy_no);
  const dcPolicy = await readDcPolicy(plcy_no);
  return generateDcInfoFromDcPolicy(deliv_due_date, dcPolicy);
};
export const todayDcInfo = async (deliv_due_date, userCountry) => {
  //console.log("contract.js : todayDcInfo, deliv_due_date=", deliv_due_date);
  //console.log("contract.js : todayDcInfo, userCountry=", userCountry);
  const dcPolicyList = await readDcPolicyList();
  return todayDcInfoWithPolicyList(deliv_due_date, dcPolicyList, userCountry);
};

const DC_POLICY_FIELDS = [
  "plcy_no",
  "dc_rate_percent",
  "dc_start_fac",
  "dc_end_fac",
  "dc_start_date",
  "dc_end_date",
];
// 이 목록을 먼저 useQuery 문으로 캐싱하여 가지고 있다가
// 필요할 때 꺼내와서 쓰도록 하면 좋음.
export const readDcPolicyList = async () => {
  //console.log("contract.js : readDcPolicyList");
  let result = null;
  const fields = DC_POLICY_FIELDS;
  const filter = { status: { _eq: "published" } };
  const sort = ["-dc_rate_percent"]; // 할인율 높은 것부터 낮은 순으로
  const { data } = await directus.items("dc_policies").readByQuery({
    fields,
    filter,
    sort,
  });
  if (data && data.length > 0) result = data;

  //console.log("contract.js : readDcPolicyList, result=", result);
  return result;
};

export const readDcPolicy = async (dc_plcy_no) => {
  //console.log("contract.js : readDcPolicy, dc_plcy_no=", dc_plcy_no);
  const result = await directus.items("dc_policies").readOne(dc_plcy_no);
  //console.log("contract.js : readDcPolicy, result=", result);
  return result;
};
/**
 * 할인정책 처리 관련 종료
 */

export const readOrderList = async (searchParams) => {
  //console.log("contract.js : readOrderList, searchParams=", searchParams);
  const searchOrderStatusList = [
    "Order Placed",
    "Preparing Product",
    "Shipping Product",
    "Delivered",
  ];
  const searchDateType = "deliv_due_date";
  return readContractList({
    ...searchParams,
    searchOrderStatusList,
    searchDateType,
  });
};
export const readCompletedList = async (searchParams) => {
  //console.log("contract.js : readOrderList, searchParams=", searchParams);
  const searchOrderStatusList = ["Completed", "Canceled"];
  const searchDateType = "compl_date";
  return readContractList({
    ...searchParams,
    searchOrderStatusList,
    searchDateType,
  });
};

const readContractList = async ({
  searchOrderStatusList,
  searchDateType = "deilv_due_date",
  searchKeyword,
  searchStartDate,
  searchEndDate,
  isPaging = true,
  limit = PAGE_LIMIT,
  page = 1,
}) => {
  //console.log("contract.js : readContractList, searchKeyword=", searchKeyword);
  //console.log("contract.js : readContractList, searchStartDate=", searchStartDate);
  //console.log("contract.js : readContractList, searchEndDate=", searchEndDate);

  let result = null;
  const orderListFilter = { _and: [] };
  orderListFilter._and.push({ status: { _eq: "published" } });
  orderListFilter._and.push({
    order_status: {
      _in: searchOrderStatusList,
    },
  });

  if (!!searchKeyword) {
    const keywordFilter = { _or: [] };
    keywordFilter._or.push({
      order_id: {
        _contains: searchKeyword,
      },
    });
    keywordFilter._or.push({
      plan_no: {
        plan_nm: {
          _contains: searchKeyword,
        },
      },
    });
    keywordFilter._or.push({
      cust_no: {
        first_name: {
          _contains: searchKeyword,
        },
      },
    });
    keywordFilter._or.push({
      cust_no: {
        mob_phone: {
          _contains: searchKeyword,
        },
      },
    });
    keywordFilter._or.push({
      cust_no: {
        email: {
          _contains: searchKeyword,
        },
      },
    });
    keywordFilter._or.push({
      recip_phone_num: {
        _contains: searchKeyword,
      },
    });
    keywordFilter._or.push({
      recip_email: {
        _contains: searchKeyword,
      },
    });
    keywordFilter._or.push({
      recip_nm: {
        _contains: searchKeyword,
      },
    });
    keywordFilter._or.push({
      deliv_addr_no: {
        addr_lower: {
          _contains: searchKeyword,
        },
      },
    });
    keywordFilter._or.push({
      deliv_addr_no: {
        addr_upper: {
          _contains: searchKeyword,
        },
      },
    });
    keywordFilter._or.push({
      deliv_addr_no: {
        addr_city_prov: {
          _contains: searchKeyword,
        },
      },
    });
    keywordFilter._or.push({
      tax_info_no: {
        comp_nm: {
          _contains: searchKeyword,
        },
      },
    });
    orderListFilter._and.push(keywordFilter);
  }

  /* 
   날짜 컬럼을 integer 타입으로 변경하여 아래와 같이 필터로 날짜 검색 처리할 것
*/
  if (searchDateType === "compl_date") {
    !!searchStartDate &&
      orderListFilter._and.push({
        compl_date_int: { _gte: Number(searchStartDate) },
      });
    !!searchEndDate &&
      orderListFilter._and.push({
        compl_date_int: { _lte: Number(searchEndDate) },
      });
  } else {
    !!searchStartDate &&
      orderListFilter._and.push({
        deliv_due_date_int: { _gte: Number(searchStartDate) },
      });
    !!searchEndDate &&
      orderListFilter._and.push({
        deliv_due_date_int: { _lte: Number(searchEndDate) },
      });
  }

  //console.log("contract.js : readContractList, orderListFilter=", orderListFilter);
  const query = {
    fields: CONTRACTS_FIELDS,
    filter: orderListFilter,
    meta: "*",
    sort: "-date_updated", //order by floor_no desc
  };

  result = await directus
    .items("contracts")
    .readByQuery(isPaging ? getPagingQuery(query, page, limit) : query);
  //console.log("contract.js : readContractList, result1=", result);

  if (result?.data?.length > 0) {
    result.totalCount = result.meta.filter_count;
    result.pageParam = page;
  }
  //console.log("contract.js : readContractList, result=", result);
  return result;
};

export const readOrderDetail = async ({ orderId }) => {
  //console.log("contract.js : readOrderDetail, orderId=", orderId);
  if (!orderId) return null;
  let result = null;
  const { data } = await directus.items("contracts").readByQuery({
    fields: CONTRACTS_FIELDS,
    filter: { order_id: { _eq: orderId.toUpperCase() } },
  });
  if (data?.length > 0) result = data[0];
  return result;
};

export const newBadgeStatusInAdmin = async (userId) => {
  //console.log("contract.js : newBadgeStatusInAdmin, userId=", userId);
  // 한번에 contract와 관련된 3개 배치 상태를 반환
  const result = {
    customer: false,
    order: false,
    //completed: false,
  };
  // 전체목록
  const { data } = await directus.items("contracts").readByQuery({
    fields: [
      "contr_no",
      "order_status",
      "status",
      //"new_item_checkers.directus_users_id",
    ],
  });
  //console.log("contract.js : newBadgeStatusInAdmin, data=", data);

  // customer
  result.customer =
    data.filter(
      (item) => item.order_status === "Confirmed" && item.status === "published"
    ).length > 0;

  // order
  result.order =
    data.filter(
      (item) => item.order_status === "Delivered" && item.status === "published"
    ).length > 0;

  //console.log("contract.js : hasNewBadgeStatusInAdmin, result=", result);
  return result;
};

/**
 * 주문 상세 조회 기록 추가
 * @param {*} param0
 * @returns
 */
export const saveCurrOrderToStorage = (orderDetail) =>
  setStorage(storageKey("order"), {
    contr_no: orderDetail.contr_no,
    order_id: orderDetail.order_id,
  });
