import React from "react";
import { useLocation } from "react-router";
import { getBtnClass } from "../../../api/directus";
import "./Dialog.css";

/* 사용법
  <SimpleDialog
  type="alert" // alert : 알림창, confirm : 확인창 
  message="취소하시겠습니까?"
  yesButtonName="아니오" // 안쓰면 "YES"가 버튼이름이 됩니다.
  noButtomName="취소"
  yesButtonOnClick = {close} // 알림창은 yesButtonOnClick 만 처리
  noButtonOnClick = {cancel}
  />
*/
function SimpleDialog({
  type = "alert",
  message,
  yesButtonName = "YES",
  noButtonName = "NO",
  yesButtonOnClick,
  noButtonOnClick,
  yesButtonClass,
  noButtonClass,
}) {
  const location = useLocation();

  // screen 값으로 버튼 클래스 선택
  let btnClass = getBtnClass(location);
  //console.log(`btnClass : ${btnClass}`);

  return (
    <div className="modal confirm">
      <div className="container">
        <div className="context">{message}</div>
        <div className="btn-wrap">
          {
            <>
              <button
                className={
                  "btn btn-s " + (yesButtonClass || btnClass) + " round shadow"
                }
                type="button"
                onClick={yesButtonOnClick}
              >
                {yesButtonName}
              </button>
              {type === "confirm" && (
                <button
                  className={
                    "btn btn-s " +
                    (noButtonClass || "type-negative") +
                    " round shadow"
                  }
                  type="button"
                  onClick={noButtonOnClick}
                >
                  {noButtonName}
                </button>
              )}
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default SimpleDialog;
