import { createSlice } from "@reduxjs/toolkit";
import {
  clearStorage,
  getStorage,
  setStorage,
  storageKey,
} from "../../api/directus";

// TODO: 추후 보안성 강화를 위하여 Key/value 값을 encrypt 하기
const initialState = () => {
  const token = getStorage(storageKey("token"));
  const resetValue = {
    id: "",
    country: "",
    role: { name: "" },
    org_no: null,
    avatar: null,
  };
  if (!token) {
    clearStorage();
    return resetValue;
  } else return getStorage(storageKey("user")) ?? resetValue;
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: initialState,
  reducers: {
    setUserInfo: (state, action) => {
      //console.log("userInfoSlice:setUserInfo-param=", action.payload);
      state.id = action.payload.id;
      state.country = action.payload.country;
      state.role.name = action.payload.role.name;
      state.org_no = action.payload.org_no ?? null;
      state.avatar = action.payload.avatar ?? null;
      state.allowed_menu = action.payload.allowed_menu ?? [];
      setStorage(storageKey("user"), state);
      //localStorage.setItem("_ssamdy_", JSON.stringify(state));
    },
    resetUserInfo: (state, action) => {
      //console.log("userInfoSlice:resetUserInfo");
      state.id = "";
      state.country = "";
      state.role.name = "";
      state.org_no = null;
      state.avatar = null;
      setStorage(storageKey("user"), state);
      //localStorage.setItem("_ssamdy_", JSON.stringify(state));
    },
  },
});

export const { setUserInfo, resetUserInfo } = userInfoSlice.actions;
export const selectUserInfo = (state) => state.userInfo;

export default userInfoSlice.reducer;
