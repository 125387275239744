import React from "react";
import SimpleDialog from "./SimpleDialog";

/*
[사용 예시 코드]
1. 컴포넌트 임포트
  import EasyDialog from "../00_common/EasyDialog";
2. 스테이트 선언
  const [dialogConfig, setDialogConfig] = useState();
3. 컴포넌트 삽입
  <EasyDialog
    dialogConfig={dialogConfig}
    setDialogConfig={setDialogConfig}
  />
4. 다이얼로그 띄우기
4.1. 추가,수정,삭제 버튼 클릭시 Confirm 모달 띄워줄때 사용
  const onClickDelete = () => {
    setDialogConfig({
      type: "confirm", //default alert
      message: "Are you sure you want to delete?",
      yesButtonName: "Delete", //default Ok
      yesButtonClass: "type-warning", //강조시에만 선언
      yesButtonOnClick: deleteProcessing,
    });
  };
4.2. Confirm 모달에서 승인 버튼 클릭한 경우 Alert 띄워줄때 사용
  const deleteProcessing = () => {
    ... 로직처리 ...
    setDialogConfig({
      message: "Deleted.",
    }); //로직처리 완료 후 alert 메시지 띄우기
  };
*/

/**
 * 다이얼로그가 여러개 필요한 경우 컴포넌트를 여러개 만들어야 하는 문제 개선
 * 다이얼로그 닫기 동작은 컴포넌트 내부에서 자동 처리함
 * @param {dialogConfig} 옵션 값
 * @param {setDialogConfig} 옵션 값 set 합수
 * @returns
 */
function EasyDialog({ dialogConfig, setDialogConfig }) {
  return (
    <>
      {!!dialogConfig?.message && (
        <SimpleDialog
          type={!!dialogConfig.type ? dialogConfig.type : undefined}
          message={dialogConfig.message}
          yesButtonClass={
            !!dialogConfig.yesButtonClass
              ? dialogConfig.yesButtonClass
              : undefined
          }
          noButtonClass={
            !!dialogConfig.noButtonClass
              ? dialogConfig.noButtonClass
              : undefined
          }
          yesButtonName={
            !!dialogConfig.yesButtonName ? dialogConfig.yesButtonName : "Ok"
          }
          noButtonName={
            !!dialogConfig.noButtonName ? dialogConfig.noButtonName : "Cancel"
          }
          yesButtonOnClick={() => {
            //닫기가 먼저 와야함
            //!!이유: confirm이 닫히면서 alert가 열릴 경우를 대비
            setDialogConfig(null);
            typeof dialogConfig.yesButtonOnClick === "function" &&
              dialogConfig.yesButtonOnClick();
          }}
          noButtonOnClick={() => {
            //닫기가 먼저 와야함
            //!!이유: confirm이 닫히면서 alert가 열릴 경우를 대비
            setDialogConfig(null);
            typeof dialogConfig.noButtonOnClick === "function" &&
              dialogConfig.noButtonOnClick();
          }}
        />
      )}
    </>
  );
}

export default EasyDialog;
