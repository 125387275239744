import { Directus } from "@directus/sdk";
import { newBadgeStatusInAdmin } from "./contract";

// PAGING
export const PAGE_LIMIT = 10;

// Image Size
export const MAX_NUMBER_OF_FILES = 1; // 첨부 파일 최대 개수
export const MAX_SIZE_PER_FILE = 5; // 첨부 파일 당 최대 용량 (MB)
export const MAX_TOT_FILE_SIZE = 5; // 첨부 파일 합계 최대 용량 (MB)

// ISO_LANGUAGE_CODE
export const ISO_LANGUAGE_CODE = "vi";
export const ISO_LAN_COUN_CODE = "vi-VN";

// Navibar New Badge Update Interval
export const NAVBAR_NEW_BADGE_UPDATE_INTERVAL = 1000 * 60 * 15; // 15min

// URL
const BASE_URL = process.env.REACT_APP_BASE_URL; //"https://ssamdy.dev01.postmedia.co.kr";
export const API_URL = BASE_URL + "/api";
export const IMG_URL_PRE = API_URL + "/assets/";
export const PASSWORD_RESET_URL = !!process.env.REACT_APP_PASSWORD_RESET_LINK
  ? process.env.REACT_APP_PASSWORD_RESET_LINK
  : BASE_URL + "/signin";

// SDK
export const directus = new Directus(API_URL);

// Common Utils For Using DB-data
export const threedUrl = (id) => (!!id ? IMG_URL_PRE + id : null);
export const imgUrl = (id) => (!!id ? IMG_URL_PRE + id : null);
export const thuImgUrl = (id) =>
  !!id ? `${IMG_URL_PRE + id}?fit=cover&width=40&height=40&quality=100` : ""; //썸네일을 꽉차게 처리
export const resizedImgUrl = (id, options) => {
  let resizedImgUrl = null;

  if (id) resizedImgUrl = IMG_URL_PRE + id;
  if (!!options && !!options.width && !!options.height) {
    if (!options.fit) options.fit = "cover";
    resizedImgUrl += `?fit=${options.fit}&width=${options.width}&height=${
      options.height
    }${options.quality ? "&quality=" + options.quality : ""}`;
  }

  return resizedImgUrl;
};
/**
 * YYYYMMDD 형식의 8자 연월일 스트링을 화면표시 형식으로 변환
 * @param {string} yyyyMMdd
 * @param {("Vietnam"|"Singapore"|"Korea")} country
 * @returns {string} 나라별 날짜 표시 형식 스트링 반환
 */
// !! FIXME : country 기본값을 "Blank","Singapore","Vietnam" 중에 선택필요함.
export const formatDate = (yyyyMMdd, country = "Vietnam") => {
  //console.log("directus.js : formatDate, yyyyMMdd=", yyyyMMdd);
  //console.log("directus.js : formatDate, country=", country);
  if (!yyyyMMdd) return null;

  let locale = toLocale(country);
  if (!locale) return `Invalid country`;

  try {
    return ymd2pureDate(yyyyMMdd).toLocaleDateString(locale);
  } catch (err) {
    console.error("directus.js : formatDate, error=", err.message);
    return `Invalid date`;
  }
};
/**
 * yyyyMMdd 형식을 date로 변환
 * [주의] 순전히 연산을 위하여 시차 등을 무시한 값으로,
 * 실제 데이트 값을 사용하려면 타임존 변환을 통해 시차를 반영해야함.
 * @param {string} yyyyMMdd
 * @returns {Date} 브라우저 현지 기준 해당날짜의 0시로 맞춰진 연산용 date값을 보내줌.
 */
export const ymd2pureDate = (yyyyMMdd) => {
  //console.log("directus.js : ymd2pureDate, yyyyMMdd=", yyyyMMdd);
  if (!yyyyMMdd) return null;
  if (yyyyMMdd.length !== 8) throw new Error("Invalid date length");
  let year = parseInt(yyyyMMdd.substring(0, 4));
  if (0 > year || year > 9999) throw new Error("Invalid year value");
  let month = parseInt(yyyyMMdd.substring(4, 6)) - 1;
  if (0 > month || month > 11) throw new Error("Invalid month value");
  let date = parseInt(yyyyMMdd.substring(6, 8));
  if (1 > date || date > 31) throw new Error("Invalid date value");
  return new Date(year, month, date);
};
const toLocale = (country) => {
  switch (country) {
    case "Vietnam":
      return "vi-VN";
    case "Singapore":
      return "en-SG";
    case "Korea":
      return "ko-KR";
    case "Blank":
      return {}; // 기본값을 사용
    default:
      return null; // 유효하지 않음 표시
  }
};
export const toTimeZone = (country) => {
  switch (country) {
    case "Vietnam":
      return { timeZone: "Asia/Saigon" };
    case "Singapore":
      return { timeZone: "Asia/Singapore" };
    case "Korea":
      return { timeZone: "Asia/Seoul" };
    case "Blank":
      return {}; // 기본값을 사용
    default:
      return null; // 유효하지 않음 표시
  }
};

/**
 * @param {string} yyyyMMdd
 * @param {number} month
 * @returns {string} yyyyMMdd 형식으로 반환
 */
export const subtractMonth = (yyyyMMdd, month) => {
  //console.log("directus.js : subtractMonth, yyyyMMdd=", yyyyMMdd);
  //console.log("directus.js : subtractMonth, month=", month);
  try {
    if (!yyyyMMdd || yyyyMMdd.length !== 8 || !month) return null;
    let pureDate = ymd2pureDate(yyyyMMdd);
    pureDate.setMonth(pureDate.getMonth() - month);
    return pureDate2ymd(pureDate);
  } catch (err) {
    console.error("directus.js : subtractMonth, error=", err.message);
  }
  return null;
};
export const addDay = (yyyyMMdd, day) => {
  //console.log("directus.js : addDay, yyyyMMdd=", yyyyMMdd);
  //console.log("directus.js : addDay, day=", day);
  try {
    if (!yyyyMMdd || yyyyMMdd.length !== 8 || !day) return null;
    let pureDate = ymd2pureDate(yyyyMMdd);
    pureDate.setDate(pureDate.getDate() + day);
    return pureDate2ymd(pureDate);
  } catch (err) {
    console.error("directus.js : addDay, error=", err.message);
  }
  return null;
};

/**
 * @param {Date} pureDate
 * @returns {string} 시차 고려 없이 형식만 yyyyMMdd 형식으로 변환
 */
export const pureDate2ymd = (pureDate) => {
  //console.log("directus.js : pureDate2ymd, pureDate=", pureDate);
  if (!pureDate) return null;
  else
    return new Date(pureDate).toLocaleDateString("sv-SE").replaceAll("-", "");
};
/**
 *
 * @param {("Vietnam"|"Singapore"|"Korea")} country
 * @returns {string} 해당 국가의 현지 시간 기준으로 yyyyMMdd 값을 반환
 */
export const todayFormattedDate = (country = "Blank") => {
  //console.log("directus.js : currentDate, country=", country);
  let timeZone = toTimeZone(country);
  if (timeZone)
    return new Date().toLocaleDateString("sv-SE", timeZone).replaceAll("-", "");
  else return `Invalid country`;
};
export const nowFormattedTime = (country = "Blank") => {
  //console.log("directus.js : currentDate, country=", country);
  let timeZone = toTimeZone(country);
  if (timeZone) return new Date().toLocaleTimeString("sv-SE", timeZone);
  else return `Invalid country`;
};

export const formatPrice = (price) =>
  (price?.toLocaleString("vi-VN") || "0") + " ₫";

export const formatNumber = (number) => number?.toLocaleString("vi-VN") || "0";
/**
 *
 * @param {object} addressObject
 * @returns {string} 전체 주소 반환
 */
export const formatAddress = (addressObject) => {
  //console.log("formatAddress: addressObject=", addressObject);
  if (!addressObject) return "(Empty address information)";
  if (typeof addressObject !== "object") return "(Not valid address)";
  const {
    addr_lower = "",
    addr_upper = "",
    addr_city_prov = "",
    addr_post_code = "",
  } = addressObject;
  return `${addr_lower ? addr_lower + ", " : ""}${
    addr_upper ? addr_upper + ", " : ""
  }${addr_city_prov ? addr_city_prov + " " : ""}${
    addr_post_code ? "[" + addr_post_code + "]" : ""
  }`;
};
export const formatName = ({ first_name = "", last_name = "" } = {}) =>
  `${first_name ? first_name : ""}${last_name ? " " + last_name : ""}`;
export const getInitials = (name) =>
  // 모든 앞글자 name.match(/(\b\S)?/g).join("");
  // 앞글자 2자만(성과 이름 이니셜)
  name
    .match(/(\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();
export const statusToPhase = (status) => {
  switch (status) {
    case "Preparing Product":
      return "Preparing";
    case "Shipping Product":
      return "Shipping";
    default:
      return status;
  }
};

export const getBtnClass = (location) => {
  const path = location.pathname;
  const screen =
    (path.match(/^\/signin/) && "manager") ||
    (path.match(/^\/admin/) && "manager") ||
    (path.match(/^\/supplier/) && "manager") ||
    (path.match(/^\/agency/) && "manager") ||
    (path.match(/^\/agent/) && "manager") ||
    (path.match(/^\/customer/) && "user") ||
    (path.match(/^\/mcustomer/) && "user");

  // screen 값으로 버튼 클래스 선택
  let btnClass;
  switch (screen) {
    case "manager":
      btnClass = "type-manager";
      break;
    case "user":
      btnClass = "type-user";
      break;
    default:
      btnClass = "type-negative";
  }
  return btnClass;
};

// Validation Pattern
export const VALID_PATTERN = {
  url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$/,
  email:
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.(([a-zA-Z]{2}.[a-zA-Z]{2})|([a-zA-Z]{3}))$/i,
  phone: /^(\d{3,4})-(\d{3})-(\d{4})$/,
  phoneOnlyNumbers: /(\d{3,4})(\d{3})(\d{4,})/,
  mobile: /^(\d{3,4})-(\d{3})-(\d{4})$/,
  mobileOnlyNumbers: /(\d{3,4})(\d{3})(\d{4,})/,
  postCode: /^[0-9]{5}$/,
  dataNo: /^#[0-9]{1,}$/,
  taxCode: /^[0-9]{10}$/,
  number: /^[0-9]{1,}$/,
  // pointNumber: /^[0-9.]{1,}$/,
};

// Error Message from SDK(API) for Dialog
export const errorMessage = (error) =>
  (!!error?.message
    ? error.message
    : error?.parent?.message || "Unknown Error!!") + " Please retry.";

/**
 * 웹브라우저 저장소에 저장
 * @param {string} key
 * @param {object} value
 * @returns {object} 저장된 값을 다시 읽어와서 반환
 */
export const setStorage = (key, value) => {
  if (typeof key !== "string" || !key) return null;
  //console.log("directus.js : setStorage, key=", key);
  let newValue = value;
  try {
    let prevValue = getStorage(key);
    if (!!prevValue) newValue = { ...prevValue, ...value }; // 이전 값이 있을 때는 지우지 않고 덮어쓰기
    localStorage.setItem(key, JSON.stringify(newValue));
  } catch (error) {
    console.log("directus.js : setStorage, error=", error);
  }
  return getStorage(key);
};
export const getStorage = (key) => {
  if (typeof key !== "string" || !key) return null;
  //console.log("directus.js : getStorage, key=", key);
  try {
    if (key === "auth_token") return localStorage.getItem(key);
    else return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    console.log("App:directus.js : getStorage, error=", error);
    return null;
  }
};
export const resetStorage = (key) => {
  if (typeof key !== "string" || !key) return false;
  //console.log("directus.js : resetStorage, key=", key);
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.log("directus.js : setStorage, error=", error);
  }
  return false;
};
export const clearStorage = () => localStorage.clear();
export const storageKey = (name, id = "") => {
  let storageKey = null;
  switch (name) {
    case "dc":
      storageKey = "dc_info_" + id;
      break;
    case "confirm":
      storageKey = "confirm_order_" + id;
      break;
    case "user":
      storageKey = "ssamdy";
      break;
    case "customer":
      storageKey = "customer";
      break;
    case "order":
      storageKey = "currOrder";
      break;
    case "suppOrder":
      storageKey = "currSuppOrder";
      break;
    case "token":
      storageKey = "auth_token";
      break;
    default:
  }
  return storageKey;
};

// role name 으로 role id 찾기
export const getRoleId = async (roleName) => {
  let { data } = await directus.roles.readByQuery({
    fields: ["id"],
    filter: { name: { _eq: roleName } },
  });
  const roleId = data[0]?.id;
  //console.log("directus:getRoleId, result=", roleId);
  return roleId;
};

// agent num + 1 값 얻기
export const nextAgentNum = async () => {
  let { data } = await directus.users.readByQuery({
    fields: ["agent_num"],
    sort: ["-agent_num"],
    limit: 1,
    filter: { agent_num: { _nnull: true } },
  });
  // let { data } = await directus.users.readByQuery({
  //   aggregate: { max: ["agent_num"] },
  // });
  const agentNum = data[0]?.agent_num + 1;
  //console.log("directus:agentNum, result=", agentNum);
  return agentNum;
};

export const getPagingQuery = (query, page, limit) => {
  let pagingQuery = { ...query };
  pagingQuery["page"] = page;
  pagingQuery["limit"] = limit;
  return pagingQuery;
};

/**
 * 파일을 저장할 폴더명에 대한 폴더 id값 가져오기
 * @param {("3d_model"|"floor"|"organization"|"plan"|"product"|"user")} folderName
 * @returns {string} 폴더 id를 UUID 형식으로 반환
 */
export const getFolderId = async (folderName) => {
  //console.log("directus.js : getFolderId, folderName=", folderName);

  let { data } = await directus.folders.readByQuery({
    fields: ["id"],
    filter: { name: { _eq: folderName } },
  });
  const folderId = data[0]?.id || null;

  //console.log("directus.js : getFolderId, result=", folderId);
  return folderId;
};

/**
 * 파일 업로드
 * @param {("3d_model"|"floor"|"organization"|"plan"|"product"|"user")} folderName
 * @param {File[]} fileInputValue 파일 타입 input 에서 가져온 값 : 기본 자바스크립트를 써서 'e.target.value'와 같이 가져오거나 react-hook-form을 사용하여 'getValues().fileInput'와 같이 가져온 값
 * @returns {(string|string[]|null)} 업로드 파일이 1개면 id값을, 여러 개이면 id값의 배열을 반환
 */
export const fileUpload = async (folderName, fileInputValue) => {
  //console.log("directus.js : fileUpload, folderName=", folderName);
  //console.log("directus.js : fileUpload, fileArray=", fileInputValue);

  let result = null;
  let fileCnt = fileInputValue?.length || 0;
  if (fileCnt > 0) {
    let formData = new FormData();
    let folderId = (await getFolderId(folderName)) || null;
    for (let i = 0; i < fileCnt; i++) {
      if (folderId) formData.append("folder", folderId);
      formData.append("file", fileInputValue[i]);
    }
    if (fileCnt === 1) {
      const data = await directus.files.createOne(formData);
      //console.log("directus.js : fileUpload, fileCnt=1, data=", data);
      result = data?.id;
    } else {
      const { data } = await directus.files.createMany(formData);
      //console.log("directus.js : fileUpload, fileCnt>1, data=", data);
      result = data?.map((uploadedFile) => uploadedFile.id);
    }
  }

  console.log("directus.js : fileUpload, result=", result);
  return result;
};

/**
 * 파일 삭제
 * @param {JSON} fileId
 */
export const fileDelete = async (fileId) => {
  console.log("directus.js : fileDelete, fileId=", fileId);
  try {
    // TODO 아직 여러 파일에 대한 테스트 안함
    // 여러 파일일때
    if (Array.isArray(fileId)) {
      const fileCnt = fileId?.length || 0;
      for (let i = 0; i < fileCnt; i++) {
        await directus.files.deleteOne(fileId[i]);
      }
    }
    // 파일 하나일때
    else {
      !!fileId && (await directus.files.deleteOne(fileId));
    }
  } catch (e) {
    console.log("directus.js : 파일 삭제 중 오류", e);
  }
};
/**
 * 이메일사용 유무
 * @param {String} chkEmail
 * @returns Boolean
 */
export const isUsedEmail = async (chkEmail) => {
  let filter = {};
  filter._and = [];
  filter._and.push({ email: { _eq: chkEmail } });

  let isUsed =
    ((await directus.users.readByQuery({ filter, fields: ["email"] }))?.data
      .length || 0) > 0
      ? true
      : false;

  return isUsed;
};

/**
 * 임시비밀번호 생성
 * @returns String
 */
export const generateTemporaryPassword = () => {
  let ranValue1 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
  // prettier-ignore
  let ranValue2 = [
    "A","B","C","D","E","F","G","H","I","J",
    "K","L","M","N","O","P","Q","R","S","T",
    "U","V","W","X","Y","Z"
  ];
  // prettier-ignore
  let ranValue3 = [
    "a","b","c","d","e","f","g","h","i","j",
    "k","l","m","n","o","p","q","r","s","t",
    "u","v","w","x","y","z"
  ];
  let ranValue4 = ["!", "@", "#", "$", "%", "^", "&", "*", "(", ")"];

  let password = "";

  for (let i = 0; i < 2; i++) {
    let ranPick1 = Math.floor(Math.random() * ranValue1.length);
    let ranPick2 = Math.floor(Math.random() * ranValue2.length);
    let ranPick3 = Math.floor(Math.random() * ranValue3.length);
    let ranPick4 = Math.floor(Math.random() * ranValue4.length);
    password =
      password +
      ranValue1[ranPick1] +
      ranValue2[ranPick2] +
      ranValue3[ranPick3] +
      ranValue4[ranPick4];
  }
  return password;
};

/**
 * 이메일로 비밀번호 초기화
 * @param {String} email
 */
export const resetPasswordByEmail = async ({ email }) => {
  const findUser = await directus.users.readByQuery({
    filter: { _and: [{ email: { _eq: email } }] },
  });

  let changePasswordUser;
  if (!!findUser && findUser?.data.length > 0) {
    let updateUserInfo = {};
    updateUserInfo.id = findUser.data[0].id;
    updateUserInfo.password = generateTemporaryPassword();
    console.log(
      `${updateUserInfo.id} 계정 비밀번호 초기화 : ${updateUserInfo.password}`
    );
    changePasswordUser = await directus.users.updateOne(
      updateUserInfo.id,
      updateUserInfo
    );

    // TODO: 비밀번호 초기화 이메일 발송 처리

    return changePasswordUser?.password;
  } else return Promise.reject(new Error("Password initialization failed."));
};

/**
 * 배열의 문자열을 쉼표로 합치기. 빈값 체크도 함.
 * @param {*} stringArray
 * @param {*} delimiter
 * @returns
 */
export const stringJoin = (stringArray, delimiter = ",") =>
  stringArray.filter(Boolean).join(delimiter);

/**
 * 관리자 Navi-Bar에 New 뱃지 표시 여부 확인
 * @param {*} userId
 * @returns
 */
export const readNavNewBadgeStatus = async (userId) => {
  // console.log("directus.js : readNavNewBadgeStatus, userId=", userId);
  let result = {
    customer: false,
    supplier: false,
    order: false,
    completed: false,
    template: false,
    floorplan: false,
  };
  if (!userId) return result;
  /* result.customer = await hasNewCustomerInAdmin(userId);
  result.order = await hasNewOrderInAdmin(userId);
  result.completed = await hasNewCompletedInAdmin(userId); */
  result = await newBadgeStatusInAdmin(userId);

  // 고객, 템플릿, 평면도는 추후 필요시 추가 예정
  //console.log("directus.js : readNavNewBadgeStatus, result=", result);
  return result;
};

// 고객별 계약목록 : "Confirmed" 상태만 New 뱃지 부여

/**
 * 등록된 3d 파일 세트 각각의 역할 정리
 */
export const convertModelData = (assetData, srgb) => {
  // prettier-ignore
  const standardTextureName = [
    "alphaMap",        // alphaMap
    "aoMap",           // aoMap
    "bumpMap",         // bumpMap
    "displacementMap", // displacementMap => disp
    "emissiveMap",     // emissiveMap
    "envMap",          // envMap
    "lightMap",        // lightMap
    "map",             // color map
    "metalnessMap",    // metalnessMap
    "normalMap",       // normalMap
    "roughnessMap",    // roughnessMap => diff

    // TODO 알 수 없는 mapping
    "albedoMap",       // none
    "diffuseMap",      // none
    "masksMap",        // none
    "specularMap",     // none
    "translucencyMap", // none
    "transmissionMap", // none
  ];

  // 최종 정보로 정리
  const asset = { mesh: null, texture: {} };

  if (!assetData?.mesh?.id) return asset;

  // mesh 경로 할당
  asset.mesh = threedUrl(assetData?.mesh?.id);

  // texture 값 할당
  // standardTextureName에 정의된 이름의 texture가 있는 경우 할당
  for (let i in standardTextureName) {
    const textureName = standardTextureName[i];
    const key = textureName.substring(0, textureName.length - 3);
    // console.log("key", key);
    if (!!assetData[key]?.id) {
      asset.texture[textureName] = threedUrl(assetData[key].id);
    }
  }

  // console.log("3d File Role Output", asset);

  return asset;
};
