import { configureStore } from "@reduxjs/toolkit";
import userInfoReducer from "./slices/userInfoSlice";
import IpadViewerSlice from "./slices/IpadViewerSlice";

export const store = configureStore({
  reducer: {
    userInfo: userInfoReducer,
    ipadViewerInfo: IpadViewerSlice,
  },
});
