import React from "react";
import { useSelector } from "react-redux";
import { selectIpadViewerInfo } from "../../../store/slices/IpadViewerSlice";

import "./IpadViewerSpinner.css";

/**
 * ipadViewer 오픈후 종료전까지 spinner 표시
 * @param {boolean} isFetching
 * @returns
 */
function IpadViewerSpinner() {
  const ipadViewerInfo = useSelector(selectIpadViewerInfo);

  return (
    <>
      {!!ipadViewerInfo?.spinner && (
        <div className="ipad-loading-wrap">
          <div className="center">
            <div className="spinner">
              <svg>
                <circle className="active" cx={50} cy={50} r={28} />
              </svg>
            </div>
            <div className="texter">Loading</div>
          </div>
        </div>
      )}
    </>
  );
}

export default IpadViewerSpinner;
