import { directus, getPagingQuery, PAGE_LIMIT } from "./directus";

export const ADDRESS_BOOK_FIELDS = [
  "addr_no",
  "addr_nm",
  "addr_type",
  "addr_lower",
  "addr_upper",
  "addr_city_prov",
  "addr_post_code",
];

export const getCities = async ({
  isPaging = true,
  limit = PAGE_LIMIT,
  page = 1,
}) => {
  const result = await directus
    .items("address_book")
    .readByQuery({ fields: ["addr_city_prov"], groupBy: ["addr_city_prov"] });

  if (isPaging) {
    result.totalCount = result.meta.filter_count;
    result.pageParam = page;
  }

  return result;
};
