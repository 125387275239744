import { createSlice } from "@reduxjs/toolkit";
import { setStorage } from "../../api/directus";

const initialState = {
  primaryKey: null,
  spinner: false,
};

export const ipadViewerSlice = createSlice({
  name: "ipadViewerInfo",
  initialState: initialState,
  reducers: {
    setIpadViewerInfo: (state, action) => {
      // console.log("IpadViewerSlice:seIpadViewer-param=", action.payload);
      state.primaryKey = action.payload.primaryKey;
      state.spinner = action.payload.spinner;
      setStorage("ipadViewer", state);
    },
    resetIpadViewerInfo: (state, action) => {
      // console.log("userInfoSlice:resetIpadViewer");
      state = { primaryKey: null, spinner: false };
      setStorage("ipadViewer", state);
    },
  },
});

export const { setIpadViewerInfo, resetIpadViewerInfo } =
  ipadViewerSlice.actions;
export const selectIpadViewerInfo = (state) => state.ipadViewerInfo;

export default ipadViewerSlice.reducer;
