import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectUserInfo } from "../../../store/slices/userInfoSlice";
import { getStorage, storageKey } from "../../../api/directus";

function AuthVerify() {
  let location = useLocation();
  let userInfo = useSelector(selectUserInfo);

  useEffect(() => {
    // 웹뷰이거나 모바일(터치스크린) 환경일 때 : 적용안함
    const agent = navigator.userAgent.toLowerCase();
    console.log("AuthVerify:agent=", agent);
    const isMobile = /iphone|ipad|ipod|android/i.test(agent);
    console.log("AuthVerify:isMobile=", isMobile);
    if (agent.includes("app:gagu")) return;
    if (isMobile) return;

    // 데스크탑 환경일 때
    let prevId = "";
    const setPrevId = () => {
      prevId = getStorage(storageKey("user"))?.id;
      console.log("TEST:prevId", prevId);
    };
    const currIdCheckAndGo = () => {
      const currId = getStorage(storageKey("user"))?.id;
      console.log("TEST:currId", currId);
      if (
        (!currId && window.location.pathname !== "/signin") ||
        prevId !== currId
      )
        window.location.href = "/signin";
    };

    // 사용자가 탭을 이동할 때 다른 탭에서 사용자정보 변경된 경우 처리
    const visiblityChangeListener = () => {
      if (document.visibilityState === "visible") currIdCheckAndGo();
      else setPrevId();
    };
    window.addEventListener("visibilitychange", visiblityChangeListener);

    // 사용자가 브라우저를 이동할 때 다른 브라우저에서 사용자정보 변경된 경우 처리
    window.addEventListener("focus", currIdCheckAndGo);
    window.addEventListener("blur", setPrevId);

    return () => {
      window.removeEventListener("visibilitychange", visiblityChangeListener);
      window.removeEventListener("focus", currIdCheckAndGo);
      window.removeEventListener("blur", setPrevId);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith("/signin")) return;
    switch (userInfo?.role.name) {
      case "customer":
      case "agency":
      case "supplier":
        if (!location.pathname.startsWith("/" + userInfo.role.name))
          window.location.href = "/signin";
        break;
      case "master":
        if (!location.pathname.startsWith("/admin"))
          window.location.href = "/signin";
        break;
      default:
    }
  }, [location]);
  return <></>;
}

export default AuthVerify;
