import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import LoadingSpinner from "./screen/00_common/global/LoadingSpinner";
import { DialogProvider } from "./screen/00_common/global/DialogContext";
import AuthVerify from "./screen/00_common/global/AuthVerify";
import IpadViewerSpinner from "./screen/00_common/global/IpadViewerSpinner";

const SignIn = lazy(() => import("./screen/10_signin/10_SignIn"));
const Agent = lazy(() => import("./screen/20_agent/20_Agent"));
const Customer = lazy(() => import("./screen/30_customer/30_Customer"));
const Supplier = lazy(() => import("./screen/40_supplier/40_Supplier"));
const Admin = lazy(() => import("./screen/50_admin/50_Admin"));
const Agency = lazy(() => import("./screen/60_agency/60_Agency"));
const CustomerMobile = lazy(() =>
  import("./screen/70_customerm/70_CustomerMobile")
);
const PageNotFound = lazy(() =>
  import("./screen/00_common/global/PageNotFound")
);

function App() {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <BrowserRouter>
        <DialogProvider>
          <Routes>
            <Route index element={<Navigate to="/signin" replace />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/agent/*" element={<Agent />} />
            <Route path="/customer/*" element={<Customer />} />
            <Route path="/supplier/*" element={<Supplier />} />
            <Route path="/admin/*" element={<Admin />} />
            <Route path="/agency/*" element={<Agency />} />
            <Route path="/mcustomer/*" element={<CustomerMobile />} />
            <Route path="/pagenotfound" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/pagenotfound" replace />} />
          </Routes>
        </DialogProvider>
        <AuthVerify />
        <IpadViewerSpinner />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
