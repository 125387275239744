import { directus, getPagingQuery, fileUpload, PAGE_LIMIT } from "./directus";

export const CATEGORY_FIELDS = [
  "id",
  "products_prod_no",
  "product_categories_prod_cat_no",
];

export const PRODUCT_FIELDS = [
  "prod_no",
  "prod_nm",
  "prod_info_url",
  "prod_type_lv1.*",
  "prod_type_lv2.*",
  "suppl_no",
  "brand_nm",
  "model_num",
  "thumb_img",
  "prod_img",
  "prod_3d_files.id", // TODO: 안쓸 예정
  "prod_3d_files.products_prod_no", // TODO: 안쓸 예정
  "prod_3d_files.directus_files_id.id", // TODO: 안쓸 예정
  "prod_3d_files.directus_files_id.filename_download", // TODO: 안쓸 예정
  "prod_3d_json", // TODO: 안쓸 예정
  "prod_img_files.id",
  "prod_img_files.directus_files_id",
  "prod_img_files.products_prod_no",
  "prod_unit_price",
  "prod_desc",
  "prod_width_mm",
  "prod_height_mm",
  "prod_depth_mm",
  "prod_size",
  "prod_weight",
  "made_by",
  "made_in",
  ...CATEGORY_FIELDS.map((field) => "cat_by_mat.".concat(field)),
  ...CATEGORY_FIELDS.map((field) => "cat_by_room.".concat(field)),
  ...CATEGORY_FIELDS.map((field) => "cat_by_style.".concat(field)),
  ...CATEGORY_FIELDS.map((field) => "cat_by_color.".concat(field)),
  "status",
  "tag",
  "prod_3d_asset.mesh.id",
  "prod_3d_asset.albedo.id",
  "prod_3d_asset.ao.id",
  "prod_3d_asset.diffuse.id",
  "prod_3d_asset.displacement.id",
  "prod_3d_asset.masks.id",
  "prod_3d_asset.normal.id",
  "prod_3d_asset.specular.id",
  "prod_3d_asset.translucency.id",
  "prod_3d_asset.transmission.id",
  "prod_3d_asset.metalness.id",
];

/**
 * 상품정보
 * @param {prod_no} prod_no
 * @returns
 */
export const getProduct = async (prod_no) => {
  let filter = {};
  filter._and = [];
  filter._and.push({ status: { _eq: "published" } });

  const result = await directus
    .items("products")
    .readOne(prod_no, { fields: PRODUCT_FIELDS, filter });

  return result;
};

/**
 * 상품정보 목록
 * @param {suppl_no,prod_type_lv1,prod_type_lv2,keyword} param0
 * @returns
 */
export const getProductList = async ({
  suppl_no, // 공급자번호
  prod_type_lv1, // 제품유형레벨1(상위)
  prod_type_lv2, // 제품유형레벨2(하위)
  color, // 색상
  keyword,
  isPaging = true,
  limit = PAGE_LIMIT,
  page = 1,
}) => {
  let filter = {};
  filter._and = [];
  filter._and.push({ status: { _eq: "published" } });
  suppl_no && filter._and.push({ suppl_no: { _eq: suppl_no } });
  prod_type_lv1 && filter._and.push({ prod_type_lv1: { _eq: prod_type_lv1 } });
  prod_type_lv2 && filter._and.push({ prod_type_lv2: { _eq: prod_type_lv2 } });
  color &&
    filter._and.push({
      cat_by_color: { product_categories_prod_cat_no: { _in: [color] } },
    });

  const query = {
    fields: PRODUCT_FIELDS,
    search: keyword,
    filter,
    sort: "-date_updated",
    meta: "*",
  };

  const result = await directus
    .items("products")
    .readByQuery(isPaging ? getPagingQuery(query, page, limit) : query);

  result.totalCount = result.meta.filter_count;
  result.pageParam = page;

  return result;
};

export const saveProduct = async ({ formData, deleteProdImgIds }) => {
  let sendData = { ...formData };
  // 파일 관련 처리는 formData 로
  sendData.prod_img_files = [];
  sendData.prod_3d_files = [];

  // 기존 서버 이미지 파일
  let serverProdImages = null;
  if (!!sendData.prod_no) {
    serverProdImages = await directus
      .items("products")
      .readOne(sendData.prod_no, { fields: ["prod_img_files.*"] });
    if (!!deleteProdImgIds && deleteProdImgIds.length > 0)
      serverProdImages = serverProdImages.prod_img_files.filter(
        (img) => !deleteProdImgIds.includes(img.id)
      );
    else serverProdImages = serverProdImages.prod_img_files.map((file) => file);
  }

  if (!!serverProdImages && serverProdImages.length > 0)
    sendData.prod_img_files = [...sendData.prod_img_files, ...serverProdImages];

  // 업로드 이미지 파일
  let uploadProdImages = [];
  if (formData.prod_img_files instanceof FileList) {
    let imgFileCnt = formData.prod_img_files?.length || 0;
    if (imgFileCnt === 1) {
      uploadProdImages = [
        {
          directus_files_id: await fileUpload(
            "product",
            formData.prod_img_files
          ),
        },
      ];
    } else if (imgFileCnt > 1) {
      uploadProdImages = (
        await fileUpload("product", formData.prod_img_files)
      ).map((file, index) => {
        return { directus_files_id: file };
      });
    } else {
      // 처리하지 않음
    }

    if (!!uploadProdImages && uploadProdImages.length > 0)
      sendData.prod_img_files = [
        ...sendData.prod_img_files,
        ...uploadProdImages,
      ];
  }

  // 임시 이미지 파일 삭제
  delete sendData.temp_prod_img_files;

  // 대표 이미지 설정
  const repProdImg = sendData?.prod_img_files?.find(
    (img) => img.id === sendData.prod_img
  );
  if (!!repProdImg === false) {
    if (!!sendData.prod_img_files && sendData.prod_img_files.length > 0)
      sendData.prod_img = sendData.prod_img_files[0].directus_files_id;
    else sendData.prod_img = null;
  }

  // 3D파일 업로드
  if (formData.prod_3d_files instanceof FileList) {
    let threedFileCnt = formData.prod_3d_files?.length || 0;
    if (threedFileCnt === 0) {
      // 기존 3D 파일 유지
      delete sendData.prod_3d_files;
    } else if (threedFileCnt === 1) {
      sendData.prod_3d_files = [
        {
          directus_files_id: await fileUpload(
            "product",
            formData.prod_3d_files
          ),
        },
      ];
    } else if (threedFileCnt > 1) {
      sendData.prod_3d_files = (
        await fileUpload("product", formData.prod_3d_files)
      ).map((file, index) => {
        return { directus_files_id: file };
      });
    } else {
      // 처리하지 않음
    }
  }

  // 재질
  if (!!formData.cat_by_mat) {
    if (Array.isArray(formData.cat_by_mat))
      sendData.cat_by_mat = formData.cat_by_mat.map((catNo) => {
        return { product_categories_prod_cat_no: catNo };
      });
    else
      sendData.cat_by_mat = [
        { product_categories_prod_cat_no: formData.cat_by_mat },
      ];
  }

  // 컬러
  if (!!formData.cat_by_color) {
    if (Array.isArray(formData.cat_by_color))
      sendData.cat_by_color = formData.cat_by_color.map((catNo) => {
        return { product_categories_prod_cat_no: catNo };
      });
    else
      sendData.cat_by_color = [
        { product_categories_prod_cat_no: formData.cat_by_color },
      ];
  }

  // 스타일
  if (!!formData.cat_by_style) {
    if (Array.isArray(formData.cat_by_style))
      sendData.cat_by_style = formData.cat_by_style.map((catNo) => {
        return { product_categories_prod_cat_no: catNo };
      });
    else
      sendData.cat_by_style = [
        { product_categories_prod_cat_no: formData.cat_by_style },
      ];
  }

  // 룸
  if (!!formData.cat_by_room) {
    if (Array.isArray(formData.cat_by_room))
      sendData.cat_by_room = formData.cat_by_room.map((catNo) => {
        return { product_categories_prod_cat_no: catNo };
      });
    else
      sendData.cat_by_room = [
        { product_categories_prod_cat_no: formData.cat_by_room },
      ];
  }

  // "." 제거
  sendData.prod_unit_price = sendData.prod_unit_price.replaceAll(".", "");
  sendData.prod_height_mm = sendData.prod_height_mm.replaceAll(".", "");
  sendData.prod_width_mm = sendData.prod_width_mm.replaceAll(".", "");
  sendData.prod_depth_mm = sendData.prod_depth_mm.replaceAll(".", "");

  let result;
  if (!!sendData.prod_no === true) {
    // 상품 업데이트 처리
    result = await directus
      .items("products")
      .updateOne(sendData.prod_no, sendData);
  } else {
    // 상품 등록 처리
    delete sendData.prod_no;
    sendData.status = "published";
    result = await directus.items("products").createOne(sendData);
  }

  return result;
};

export const deleteProduct = async (prod_no) => {
  // status archived update 로 삭제 처리
  const result = await directus
    .items("products")
    .updateOne(prod_no, { status: "archived" });

  return result;
};
