import { createContext, useState } from "react";
import EasyDialog from "./EasyDialog";

export const DialogContext = createContext();

/**
 * EasyDailog를 Context로 구현
 *
 * [사용법]
 * 1. 구현할 컴포넌트 내에서 DialogContext 임포트
 * import { DialogContext } from "../00_common/global/DialogContext.js";
 * 2. 글로벌 props 받기
 * const setDialogConfig = useContext(DialogContext);
 * 3. 다이얼로그 띄우기
 * const onClickDelete = () => {
 *   setDialogConfig({
 *     type: "confirm", //default alert
 *     message: "Are you sure you want to delete?",
 *     yesButtonName: "Delete", //default Ok
 *     yesButtonClass: "type-warning", //강조시에만 선언
 *     yesButtonOnClick: () => {}, //function
 *   });
 * };
 *
 * @param {children}  하위 요소
 * @returns
 */
export function DialogProvider({ children }) {
  const [dialogConfig, setDialogConfig] = useState();

  return (
    <DialogContext.Provider value={setDialogConfig}>
      {children}
      <EasyDialog
        dialogConfig={dialogConfig}
        setDialogConfig={setDialogConfig}
      />
    </DialogContext.Provider>
  );
}
