import { directus, getPagingQuery, PAGE_LIMIT } from "./directus";
import { PRODUCT_FIELDS } from "./product.js";

export const PLAN_ITEMS_FIELDS = [
  "item_no",
  "plan_no",
  "prod_no",
  ...PRODUCT_FIELDS.map((field) => "prod_no.".concat(field)),
  "item_price",
  "item_3d_json",
];

/**
 * 플랜 상품 목록
 * @param {*} plan_no
 * @returns
 */
export const getPlanItemList = async ({
  plan_no,
  isPaging = true,
  limit = PAGE_LIMIT,
  page = 1,
}) => {
  let filter = {};
  filter._and = [];
  plan_no && filter._and.push({ plan_no: { _eq: plan_no } });

  const query = {
    fields: PLAN_ITEMS_FIELDS,
    filter,
    meta: "*",
  };

  const result = await directus
    .items("plan_items")
    .readByQuery(isPaging ? getPagingQuery(query, page, limit) : query);

  result.totalCount = result.meta.filter_count;
  result.pageParam = page;

  return result.data;
};

/**
 * @typedef {Object} OrderListCountSum
 * @property {object[]} productOrderList
 * @property {number} productOrderCount
 * @property {number} orderPriceSum
 */
/**
 * planItemList를 주문서용 제품목록으로 변경
 * @param {planItem[]} planItems 플랜물품목록
 * @param {("selling"|"ordered")} whichPrice 현재 판매중인 가격(주문서 작성용) 또는 주문당시 가격(주문내역확인용)을 선택
 * @param {number} dcRatePercent 할인율(%)
 * @returns {{productOrderList : object[], productOrderCount : number, orderPriceSum : number}} orderListCountSum
 */
export const planItems2orderListCountSum = (
  planItems,
  whichPrice,
  dcRatePercent
) => {
  //console.log("planitem.js : planItems2orderListCountSum, planItems=", planItems);
  //console.log("planitem.js : planItems2orderListCountSum, whichPrice=", whichPrice);
  //console.log("planitem.js : planItems2orderListCountSum, dcRatePercent=", dcRatePercent);
  if (!planItems || planItems.length === 0) return null;
  const productOrderList = planItems2orderList(planItems, whichPrice);
  const productOrderCount = productOrderList.length;
  const productPriceSum = productOrderList.reduce(
    (sum, item) => sum + item.order_price,
    0
  );
  const result = {
    productOrderList,
    productOrderCount,
    productPriceSum,
  };
  if (!!dcRatePercent || dcRatePercent === 0) {
    result.dcAmount = Math.floor(productPriceSum * dcRatePercent * 0.01);
    result.finalPriceTot = productPriceSum - result.dcAmount;
  }
  //console.log("planitem.js : planItems2orderListCountSum, result=", result);
  return result;
};
const planItems2orderList = (planItems, whichPrice = "ordered") =>
  planItems.reduce((orderList, planItem) => {
    let index = orderList.findIndex(
      (orderItem) => orderItem.prod_no === planItem.prod_no.prod_no
    );
    if (index < 0) orderList.push(planItem2orderItem(planItem, whichPrice));
    else {
      orderList[index].order_qty += 1;
      orderList[index].order_price +=
        whichPrice === "selling"
          ? planItem.prod_no.prod_unit_price
          : planItem.item_price;
    }
    return orderList;
  }, []);
const planItem2orderItem = (planItem, whichPrice = "ordered") => {
  return {
    prod_no: planItem.prod_no.prod_no,
    prod_nm: planItem.prod_no.prod_nm,
    prod_img: planItem.prod_no.prod_img,
    brand_nm: planItem.prod_no.brand_nm,
    suppl_no: planItem.prod_no.suppl_no,
    order_qty: 1,
    order_price:
      whichPrice === "selling"
        ? planItem.prod_no.prod_unit_price
        : planItem.item_price,
  };
};

export const orderListBySupplier = async ({
  contr_no,
  plan_no,
  pickSupplNo,
  dc_rate_percent,
}) => {
  /* console.log("planitem.js : orderListBySupplier, contr_no=", contr_no);
  console.log("planitem.js : orderListBySupplier, plan_no=", plan_no);
  console.log(
    "planitem.js : orderListBySupplier, dc_rate_percent=",
    dc_rate_percent
  ); */

  // 1. 전체 물품목록 가져오기
  let { data: planItems } = await directus.items("plan_items").readByQuery({
    fields: PLAN_ITEMS_FIELDS,
    filter: { plan_no: { _eq: plan_no } },
  });
  //console.log("planitem.js : orderListBySupplier, planItems=", planItems);

  // 특정 공급자만 처리시
  if (!!pickSupplNo)
    planItems = planItems.filter((item) => {
      //console.log(`prod org_no = ${item.prod_no.suppl_no} : ${pickSupplNo}`);
      return item.prod_no.suppl_no === pickSupplNo;
    });

  // 2. 공급자 목록을 뽑는다.
  let result = null;
  const { data: supplierList } = await directus
    .items("supplier_orders")
    .readByQuery({
      fields: [
        "supp_org_no.org_no",
        "supp_org_no.org_nm",
        "supp_org_no.org_rep_img",
        "deliv_status",
        "deliv_compl_date",
      ],
      filter: { contr_no: { _eq: contr_no } },
    });
  //console.log("planitem.js : orderListBySupplier, supplierList=", supplierList);

  // 3. 공급자 별로 공급자 정보와 공급자별 주문 제품정보를 묶어서 배열로 만든다.
  if (supplierList?.length > 0) {
    result = [];
    for (let supplier of supplierList) {
      // 해당 공급자의 제품이 없으면 패스
      if (
        planItems.findIndex(
          (planItem) =>
            planItem.prod_no.suppl_no === supplier.supp_org_no.org_no
        ) < 0
      )
        continue;

      // 공급자별 주문 제품 정보 5항목
      // productOrderList : {prod_no,prod_nm,prod_img,brand_nm,suppl_no,order_qty,order_price}[],
      // productOrderCount,
      // productPriceSum,
      // dcAmount,
      // finalPriceTot
      const orderListCountSum = planItems2orderListCountSum(
        planItems.filter(
          (planItem) =>
            planItem.prod_no.suppl_no === supplier.supp_org_no.org_no
        ),
        "ordered",
        dc_rate_percent
      );
      /* console.log(
        "planitem.js : orderListBySupplier, orderListCountSum=",
        orderListCountSum
      ); */

      const supplierOrder = {
        ...orderListCountSum,
        // 공급자 및 배송정보 5항목 추가
        suppl_no: supplier.supp_org_no.org_no,
        suppl_nm: supplier.supp_org_no.org_nm,
        suppl_img: supplier.supp_org_no.org_rep_img,
        deliv_status: supplier.deliv_status,
        deliv_compl_date: supplier.deliv_compl_date,
      };
      /* console.log(
        "planitem.js : orderListBySupplier, supplierOrder=",
        supplierOrder
      ); */

      result.push(supplierOrder);
    }
  }
  //console.log("planitem.js : orderListBySupplier, result=", result);
  return result;
};

export const updateItemPrices = async (plan_no) => {
  //console.log("planitem.js : updateItemPrices, plan_no=", plan_no);
  let result = null;

  // 1. plan_no로 plan_item 목록을 가져옴. 이때 prod_no.prod_unit_price 값도 포함되게
  const { data: planItems } = await directus.items("plan_items").readByQuery({
    fields: PLAN_ITEMS_FIELDS,
    filter: { plan_no: { _eq: plan_no } },
  });
  //console.log("planitem.js : updateItemPrices, planItems=", planItems);

  //2. planItems에서 업데이트할 가격목록을 추출
  const itemPrices = getItemPricesForUpdate(planItems);
  //console.log("planitem.js : updateItemPrices, itemPrices=", itemPrices);

  // 3. 업데이트 실행 : plans의 plan_items를 통해서 업데이트
  if (itemPrices?.length > 0) {
    result = await directus
      .items("plans")
      .updateOne(plan_no, { plan_items: itemPrices });
  }
  //console.log("planitem.js : updateItemPrices, result=", result);
  return result;
};

export const getItemPricesForUpdate = (planItems) => {
  //console.log("planitem.js : getItemPricesForUpdate, planItems=", planItems);
  let result = null;
  if (planItems?.length > 0) {
    const itemPrices = [];
    for (let planItem of planItems) {
      itemPrices.push({
        item_no: planItem.item_no,
        item_price: planItem.prod_no.prod_unit_price,
      });
    }
    if (itemPrices.length > 0) result = itemPrices;
  }
  //console.log("planitem.js : getItemPricesForUpdate, result=", result);
  return result;
};
